import { BlockArray, renderBlock } from '@blocks'
import React, { useState } from 'react'
import { PageBlockId, SchoolPage } from '@/types'
import { useQueryParams } from '@/utils/useQueryParams'
import { MessageFromEditor } from '@/editor/messageProtocol'
import { useEventListener, useUpdateEffect } from 'ahooks'
import { setIsPreview } from '@blocks/useIsPreview'
import PreviewBar from '@/components/PreviewBar'
import SchoolPageHead from '@/components/SchoolPageHead'

interface Props {
  page?: SchoolPage
  blocks: BlockArray
}

const isInViewport = function (elem: Element) {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

const PreviewPageRenderer: React.FC<{ initialsBlocks: Props['blocks'], previewSessionId: string }> = ({
  initialsBlocks,
  previewSessionId
}) => {
  const [ blocks, setBlocks ] = useState(initialsBlocks)
  const [ highlightedBlockId, setHighlighted ] = useState<PageBlockId | null>(null)

  const onNewMessage = ({ data: msg }: MessageEvent<MessageFromEditor>) => {
    if (msg.previewSessionId !== previewSessionId) {
      return
    }
    switch (msg._type) {
      case 'HoveredBlock':
        setHighlighted(msg.blockId)
        return
      case 'UpdateBlocks':
        setBlocks(msg.blocks)
        return
    }
  }
  useEventListener('message', onNewMessage);

  useUpdateEffect(() => {
    const el = highlightedBlockId ? document.querySelector(`[data-block="${highlightedBlockId}"]`) : null
    if (el && el.scrollIntoView && !isInViewport(el)) {
      el.scrollIntoView({ behavior: 'smooth' })
    }
  }, [ highlightedBlockId ])

  return <>
    {blocks.map(renderBlock)}
  </>
}


const PageRenderer: React.FC<Props> = ({ page, blocks }) => {
  const { preview } = useQueryParams<{ preview?: string }>()
  setIsPreview(!!preview)

  return <>
    {!preview && <PreviewBar/>}
    {page && <SchoolPageHead page={page}/>}
    {preview ?
      <PreviewPageRenderer
        initialsBlocks={blocks}
        previewSessionId={preview}
      /> :
      blocks.map(renderBlock)}
  </>
}

export default PageRenderer
