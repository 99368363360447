import React from 'react'
import { Result } from 'antd'
import { NAV } from '@/nav'
import { ApolloError } from '@apollo/client'
import { NotFound as NotFoundResult } from './NotFound'
import { normalizeGraphqlErrors } from '@/utils/handleGraphqlError'
import Link from 'next/link'
import { WarningTwoTone as IconWarning } from '@ant-design/icons'
import SchoolPageHead from '@/components/SchoolPageHead'

export const ErrorResult: React.FC<{ error: ApolloError }> = ({ error }) => {
  const { isError, isAuthError } = normalizeGraphqlErrors(error)

  const backBtn = (
    <Link href={NAV.MyCourses} passHref>
      <a className="ant-btn ant-btn-primary">
        Перейти на главную
      </a>
    </Link>
  )

  if (isError('NotFound')) {
    return <NotFoundResult/>
  }

  if (isAuthError) {
    return <>
      <SchoolPageHead pageTitle={'Сессия устарела'}/>
      <Result
        status="warning"
        title="Сессия устарела"
        subTitle="Ваша сессия устарела, необходимо авторизоваться заново"
      />
    </>
  }

  if (isError('CourseIsNotPublished')) {
    return <>
      <SchoolPageHead pageTitle={'Курс недоступен'}/>
      <Result
        icon={<IconWarning twoToneColor={'#ffa940'}/>}
        title="Курс недоступен"
        subTitle="Этот курс еще не опубликован. Свяжитесь с автором"
        extra={backBtn}
      />
    </>
  }

  if (isError('NoAvailableStudentSeat')) {
    return <>
      <SchoolPageHead pageTitle={'Нет доступа'}/>
      <Result
        status="warning"
        title="Нет доступа"
        subTitle="Доступ к урокам временно ограничен. Свяжитесь с вашим преподавателем"
        extra={backBtn}
      />
    </>
  }

  if (isError('AccessDenied')) {
    return <>
      <SchoolPageHead pageTitle={'Нет доступа'}/>
      <Result
        status="403"
        title="Нет доступа"
        subTitle="У вас нет доступа к запрашиваемому ресурсу"
        extra={backBtn}
      />
    </>
  }

  return <>
    <SchoolPageHead pageTitle={'Ошибка при запросе'}/>
    <Result
      status="500"
      title="Ошибка при запросе"
      subTitle="Наш сервер ответил ошибкой на ваш запрос, попробуйте позже."
      extra={backBtn}
    />
  </>
}
