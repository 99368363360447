const Base = '/app'

export const NAV = {
  Landing: '/',
  Home: `${Base}/home`,
  MyCourses: `${Base}/courses/my`,
  Course: {
    Index: `${Base}/course/[id]`,
    Lecture: `${Base}/course/[id]/lecture/[lectureId]`,
  },
  ResetPassword: {
    Request: '/reset-password/request',
    Complete: '/reset-password/change',
  },
  Login: `/login`,
  Signup: `/registration`,
  Profile: `${Base}/profile`,
  Notifications: `${Base}/notifications`,
  PaymentWaiting: `${Base}/payment-waiting`,
}
