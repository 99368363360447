import React from 'react'
import { Result } from 'antd'
import { NAV } from '@/nav'
import Link from 'next/link'

export const NotFound = () => (
  <Result
    status="404"
    title="Неверная ссылка"
    subTitle="По этой ссылке ничего не найдено. Возможно, вы неправильно скопировали или она уже устарела."
    extra={
      <Link href={NAV.MyCourses} passHref>
        <a className="ant-btn ant-btn-primary">
          Перейти на главную
        </a>
      </Link>
    }
  />
)
