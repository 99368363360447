import React, { createContext, useContext } from 'react';
import { AvailableCourseQuery, useAvailableCourseQuery } from '@/graphql/generated'
import { CourseTariffLink } from '@/types'
import { Skeleton } from 'antd'
import { ErrorResult } from '@/components/ErrorResult'
import { NotFound } from '@/components/NotFound'

export interface EnrollPageContext {
  course: AvailableCourseQuery['availableCourse']
  link: CourseTariffLink
}

const EnrollPageReactContext = createContext<EnrollPageContext>({} as EnrollPageContext)

export const EnrollPageCtx: React.FC<{ link: CourseTariffLink }> = ({ link, children }) => {

  const { data, error, loading } = useAvailableCourseQuery({
    variables: { link }
  })

  if (loading) {
    return <Skeleton/>
  }
  if (error) {
    return <ErrorResult error={error}/>
  }
  if (!data) {
    return <NotFound/>
  }

  const { availableCourse: course } = data

  return <EnrollPageReactContext.Provider value={{ course, link }}>
    {children}
  </EnrollPageReactContext.Provider>
}

export function useEnrollPageCtx() {
  return useContext(EnrollPageReactContext)
}

