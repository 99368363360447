import React from 'react'
import styled from '@emotion/styled'
import { EyeOutlined as IconEye } from '@ant-design/icons'
import {colors} from '@/styles'
import {useAuthContext} from '@/auth/AuthContext'
import {useQueryParams} from '@/utils/useQueryParams'
import xw from 'xwind'

const PreviewBarContent = styled.div`
  flex: 0 0 2.5rem;
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.blue};
  color: ${colors.white};
`
const PreviewText = styled.span`
  font-weight: 600;
  margin: 0 2rem 0 1rem;
`

const PreviewBar: React.FC = () => {
  const { user } = useAuthContext()
  const { id } = useQueryParams<{id: string}>()
  const { lectureId } = useQueryParams<{lectureId: string}>()

  if (!user || !user.isTeacherPreview) {
    return null
  }

  const baseURL = `${process.env.NEXT_PUBLIC_TEACHER_UI}/admin/app`
  let href = baseURL

  if (lectureId) {
    href = `${baseURL}/course/${id}/structure/lecture/${lectureId}`
  } else if (id) {
    href = `${baseURL}/course/${id}/structure`
  }

  return (
    <PreviewBarContent>
      <IconEye style={{fontSize: '1.25rem'}}/>
      <PreviewText css={xw`hidden sm:block`}>Вы находитесь в режиме предпросмотра</PreviewText>
      <PreviewText css={xw`sm:hidden`}>Предпросмотр</PreviewText>
      <a css={xw`hidden sm:block`} className="ant-btn ant-btn-round ant-btn-sm ant-btn-background-ghost" href={href} style={{fontSize: '.75rem'}}>
        Вернуться к редактированию
      </a>
      <a css={xw`sm:hidden`} className="ant-btn ant-btn-round ant-btn-sm ant-btn-background-ghost" href={href} style={{fontSize: '.75rem'}}>
        Вернуться
      </a>
    </PreviewBarContent>
  )
}

export default PreviewBar
