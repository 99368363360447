import { ApolloError } from '@apollo/client'
import { Button, message, notification } from 'antd'
import React from 'react'
import { isMacLike } from '@/const'

export function normalizeGraphqlErrors({ graphQLErrors, networkError }: ApolloError) {
  let errors = graphQLErrors ?? []
  if (networkError && 'result' in networkError && Array.isArray(networkError.result.errors)) {
    errors = errors.concat(networkError.result.errors)
  }
  errors = errors.map(e => ({
    ...e,
    name: e.name ?? 'unknown',
    message: e.message ?? 'Неожиданная ошибка',
  }))

  const isError = (name: string, ...rest: string[]) => {
    const names = [name, ...rest]
    return errors.some(e => names.includes(e.name))
  }

  const isAuthError = isError(
    'AuthError',
    'AuthenticationError',
    'InvalidAccessToken',
    'NoAuthorizationHeader'
  )

  const hasStatusCode = (status: number) => {
    return !!networkError && 'statusCode' in networkError && networkError.statusCode === status
  }

  const errorMessage = errors.length > 0 ? errors.map(e => e.message).join('\n') : 'Неожиданная ошибка'
  return { errors, isAuthError, isError, errorMessage, hasStatusCode }
}

export const handleMutationError = (e: unknown, defaultMessage = 'Ошибка при отправке запроса') => {
  if (e instanceof ApolloError) {
    const { errors, isError, isAuthError, errorMessage, hasStatusCode } = normalizeGraphqlErrors(e)

    if (isAuthError) {
      notification.warning({
        message: 'Ваша сессия устарела',
        description: <>
          <p>Необходимо авторизоваться заново</p>
          <Button type='primary' href={'/logout'}>Войти</Button>
        </>,
        duration: 0
      })
      return
    }

    if (hasStatusCode(400)) {
      notification.error({
        message: 'Обновите страницу',
        description: (
          <>
            <p>Ваша страница сделала некорректный запрос к серверу, скорее всего вам надо сбросить кеш.</p>
            <p>Нажмите {isMacLike ? '⌘ Cmd + ⇧ Shift + R' : 'Ctrl+F5'}</p>
          </>
        )
      })
      return
    }

    let message = defaultMessage
    let description = <span>{errorMessage}</span>

    if (errors.some(e => (e.message ?? '').includes('Invalid password'))) {
      description = <span>Указан неверный текущий пароль</span>
    }

    if (isError('AccessDenied')) {
      message = 'Нет доступа'
      description = <span>У вас нет доступа к запрашиваемому ресурсу</span>
    }

    if (isError('NoAvailableStudentSeat')) {
      message = 'Нет доступа'
      description = <span>Доступ к урокам временно ограничен. Свяжитесь с вашим преподавателем</span>
    }

    notification.error({
      message,
      description
    })
    return
  }
  message.error(defaultMessage)
}
