import React from 'react'
import {useCurrentSchool} from '@/auth/AuthContext'
import Head from 'next/head'
import {SchoolPage} from '@/types'
import {useEnrollPageCtx} from '@/pages.impl/enroll/EnrollPageCtx'
import {useQueryParams} from '@/utils/useQueryParams'
import {useSSRCurrentURL} from '@/pages.impl/SSRCurrentURL'

type Props = { page: SchoolPage } | { pageTitle: string }

const MAX_DESCRIPTION = 200

const SchoolPageHead: React.FC<Props> = (props) => {
  const url = useSSRCurrentURL()
  const { preview } = useQueryParams<{ preview?: string }>()

  function isPage(p: Props): p is { page: SchoolPage } {
    return !!(p as any)?.page
  }

  if (preview) {
    return <></>
  }

  if (!isPage(props)) {
    return <Head>
      <title>{props.pageTitle}</title>
    </Head>
  }

  const { page } = props

  const school = useCurrentSchool()

  if (page._type === 'Enroll') {
    const { title, image, description } = useEnrollPageCtx().course
    const img = (image) ? image : school.avatar
    return <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} key="title" />
      {img &&
      <meta property="og:image" content={img} key="image" />}
      {description &&
      <meta property="og:description" content={description.slice(0, MAX_DESCRIPTION)} key="description" />}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
    </Head>
  }

  const title = page.browserTitle || school.name

  return <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} key="title" />
      {school.avatar &&
      <meta property="og:image" content={school.avatar} key="image" />}
      {page.description &&
      <meta property="og:description" content={page.description.slice(0, MAX_DESCRIPTION)} key="description" />}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
    </Head>
}

export default SchoolPageHead
